import { Center, Pressable, Text } from 'native-base'
import { isMobile } from 'react-device-detect'

// TODO: Remove use of MUI Box component and replace with NativeBase
import Box from '@mui/material/Box'

import { trackEvent } from '../utils/analytics-v2'

const ScanOrDownload = () => {
  const handleLinkClick = (href: string, type: 'ios' | 'android') => {
    trackEvent('download_app', { element: 'button', platform: type })
    window.open(href, '_self')
  }

  return (
    <Center style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {!isMobile && (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginBottom: '32px',
            marginTop: '44px',
          }}
        >
          <img
            src="/qr-removebg.png"
            alt="qrscan"
            style={{ width: '120px', height: '120px', objectFit: 'contain', backgroundColor: 'transparent' }}
          />
        </Box>
      )}
      <Box paddingTop={isMobile ? 6 : undefined}>
        <Text fontFamily="Matter Bold" fontWeight={'700'} fontSize={'md'} letterSpacing={'-0.48px'}>
          Try our Veho app!
        </Text>
      </Box>
      <Box
        style={{
          marginBottom: '32px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
        paddingX={isMobile ? 1.5 : undefined}
        fontWeight={400}
      >
        <Text fontWeight={'400'} fontSize={'sm'}>
          Track your shipments and edit delivery instructions.
        </Text>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '275px',
          marginBottom: '32px',
        }}
      >
        <Box>
          <Pressable
            id="downloadIOS"
            onPress={() => handleLinkClick('https://apps.apple.com/us/app/veho-mobile/id1618482681', 'ios')}
          >
            <img src="/app-store.svg" alt="" />
          </Pressable>
        </Box>
        <Box>
          <Pressable
            id="downloadAndroid"
            onPress={() =>
              handleLinkClick(
                'https://play.google.com/store/apps/details?id=com.veho.consumermobile&hl=en_US&gl=US&pli=1',
                'android'
              )
            }
          >
            <img src="/play-store.svg" alt="" />
          </Pressable>
        </Box>
      </Box>
    </Center>
  )
}

export default ScanOrDownload
